import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConstancias(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-salud/constancias', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addConstancia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/servicio-salud/constancias', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateConstancia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/servicio-salud/constancias/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteConstancia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-salud/constancias/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    vistoBuenoConstancia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-salud/constancias/${data.id}/visto-bueno`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Servicios
    fetchServicios() {
      return new Promise((resolve, reject) => {
        axios
          .get('/salud/tipos/servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
