<template>
  <b-modal
    id="modal_add_constancia"
    v-model="dialog"
    :title="vamosCrear ? 'Emitir nueva constancia' : 'Editar constancia'"
    title-class="text-white"
    ok-only
    size="lg"
    :header-bg-variant="vamosCrear ? 'primary' : 'warning'"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <buscar-alumno
        v-if="vamosCrear"
        @alumnoSeleccionado="alumnoSeleccionado"
      />
      <!-- <div
        v-if="dialog"
        class="border rounded py-1"
      >
        <h3 class="text-center text-primary">
          <strong>AL ESTUDIANTE:</strong> <span class=" ml-1 text-align-center text-primary">{{ editedItem.persona.nombre_completo }}
            - <span class="text-danger">{{ editedItem.persona.numero_documento === '' ? 'sin documento' :editedItem.numero_documento }}</span>
          </span>
        </h3>
      </div> -->
      <div
        class="border rounded py-1"
      >
        <h3 class="text-center text-primary">
          <strong>AL ESTUDIANTE:</strong> <span class=" ml-1 text-align-center text-primary">{{ estudianteSelect.nombres }}
            - <span class="text-danger">{{ estudianteSelect.numero_documento === '' ? 'sin documento' :estudianteSelect.numero_documento }}</span>
          </span>
        </h3>
      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <validation-provider
          #default="validationContext"
          name="fechaEmision"
          rules="required"
        >
          <b-form-group
            label="Fecha de emisión"
            label-for="servicio"
          >
            <b-form-input
              v-model="editedItem.fecha_emision"
              type="date"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        >
          <b-form-group
            label="se le atendió en el servicio en (el/los) servicio(s)"
            label-for="servicio"
          >
            <b-form-input
              v-model="editedItem.servicio"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="sintomas"
          rules="required"
        >
          <b-form-group
            label="por presentar sintomatología/tratamiento/control/derivación"
            label-for="sintomas"
          >
            <b-form-input
              v-model="editedItem.detalles"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="procedimientos"
          rules="required"
        >
          <b-form-group
            label="se ha realizado el procedimiento"
            label-for="procedimientos"
          >
            <b-form-input
              v-model="editedItem.nombre_procedimiento"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="sugerencia"
          rules="required"
        >
          <b-form-group
            label="Se sugiere"
            label-for="sugerencia"
          >
            <b-form-input
              v-model="editedItem.sugerencia"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="consideracion"
          rules="required"
        >
          <b-form-group
            label="Se solicita consideración"
            label-for="consideracion"
          >
            <b-form-input
              v-model="editedItem.consideracion"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="fechaConsideracion"
          rules="required"
        >
          <b-form-group
            label="Fecha de cosideración"
            label-for="fechaConsideracion"
          >
            <b-form-input
              v-model="editedItem.fecha_consideracion"
              type="date"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between ">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="closeModal"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="vamosCrear ? 'primary' : 'warning'"
            type="submit"
          >
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">{{ vamosCrear ? 'Guardar' : 'Actualizar' }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import BuscarAlumno from './BuscarAlumno.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BuscarAlumno,
  },
  directives: {
    Ripple,
  },
  props: {
    vamosCrear: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {}
      },
    },
    estudianteSelect: {
      type: Object,
      default() {
        return {
          nombres: null,
          usuario_id: null,
          numero_documento: null,
        }
      },
    },
  },
  data() {
    return {
      avatarText,
      required,
      nombreBotonModal: '',
      constancia: {},
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:dialog', false)
    },
    alumnoSeleccionado(item) {
      // console.log('ALUMNO SELCET: ', item)
      this.$set(this.estudianteSelect, 'nombres', `${item.persona.apellido_paterno} ${item.persona.apellido_materno} ${item.persona.nombres}`)
      this.$set(this.estudianteSelect, 'usuario_id', item.id)
      this.$set(this.estudianteSelect, 'numero_documento', item.persona.numero_documento)
    },

    onSubmit() {
      if (this.estudianteSelect.usuario_id === undefined && this.vamosCrear) {
        this.toastAlert('Debe de seleccionar al paciente')
        return
      }
      if (this.vamosCrear) {
        this.createConstancia()
      } else {
        this.updateConstancia()
      }
    },
    createConstancia() {
      // console.log(this.editedItem)
      this.editedItem.usuario_id = this.estudianteSelect.usuario_id
      store.dispatch('constancias/addConstancia', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(error => {
          this.toastError(`${error.response.data.error}`)
        })
    },
    updateConstancia() {
      store.dispatch('constancias/updateConstancia', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(error => {
          this.toastError(`${error.response.data.error}`)
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
