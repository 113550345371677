<template>
  <section>
    <b-card no-body>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="newItem"
              >
                Nuevo
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Main table element -->
      <b-table
        ref="refTablaConstancias"
        hover
        show-empty
        responsive
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        class="relative-position h-min-200"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(usuario)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="'light-primary'"
                :text="avatarText(row.item.persona.nombre_completo)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ row.item.persona.nombre_completo }}
            </b-link>
            <small class="text-muted text-monospace py-0">DNI° {{ row.item.persona.numero_documento }} - CEL: {{ row.item.persona.celular ? row.item.persona.celular : '---------' }}</small>
          </b-media>
        </template>

        <template #cell(visto_bueno)="row">
          <b-badge :variant="row.item.visto_bueno ? 'success' : 'danger'">
            {{ row.item.visto_bueno ? 'Sí' : 'No' }}
          </b-badge>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="15"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editItem(row.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="vistoBuenoItem(row.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Dar visto bueno</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="descargarPdf(row.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Ver</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteItem(row.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>

    <ConstanciaFormModal
      :dialog.sync="dialog"
      :edited-item="editedItem"
      :vamos-crear.sync="vamosCrear"
      :estudiante-select="estudianteSelect"
      @actualizar-tabla="actualizarTabla"
    />

  </section>
</template>

<script>
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import {
  BTable, BCard, BRow, BCol, BSpinner, BPagination, BFormInput,
  BMedia,
  BAvatar,
  BLink,
  BButton,
  BBadge,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useStoreConstancia from './useStoreConstancia'
import ConstanciaFormModal from './components/ConstanciaFormModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    vSelect,
    BLink,
    BButton,
    BMedia,
    BBadge,
    BAvatar,
    BSpinner,
    BDropdown,
    BFormInput,
    BPagination,
    BDropdownItem,
    ConstanciaFormModal,

  },
  data() {
    return {
      editedItem: {},
      defaultItem: {},
      dialog: false,
      vamosCrear: true,
      estudianteSelect: {},
      defaultItemSelect: {},
      paciente: {},
      usuario_id: null,
      items: [],
      fields: [
        {
          key: 'usuario', label: 'Paciente',
        },
        {
          key: 'codigo_identificador', label: 'cod',
        },
        {
          key: 'fecha_emision', label: 'F. Emisión',
        },
        {
          key: 'created_at', label: 'Fecha Registro',
        },
        {
          key: 'servidor_publico.name', label: 'Responsable',
        },
        {
          key: 'visto_bueno', label: 'VB',
        },
        { key: 'actions', label: 'Acciones' },
      ],
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },
  methods: {
    newItem() {
      this.dialog = true
    },
    close() {
      this.dialog = false // Cerramos el modal
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.estudianteSelect = { ...this.defaultItemSelect }
        this.vamosCrear = true
      })
    },
    editItem(item) {
      this.editedItem = { ...item }
      this.estudianteSelect.nombres = item.persona.nombre_completo
      this.estudianteSelect.usuario_id = item.usuario_id
      this.estudianteSelect.numero_documento = item.persona.numero_documento
      console.log('para editar', this.editedItem)
      this.dialog = true // Para abrir el modal
      this.vamosCrear = false
    },
    descargarPdf(item) {
      this.$router.replace({ name: 'download-pdf-constancia', params: { id: item.codigo_identificador } })
    },
    vistoBuenoItem(item) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡Despues de esto no podrás editar, eliminar ni revertir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, dar visto bueno!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('constancias/vistoBuenoConstancia', item)
            .then(response => {
              this.toastSuccess(`${response.data.usuario_persona.nombre_completo}`)
              this.actualizarTabla()
            })
            .catch(error => {
              this.toastError(`${error.response.data.error}`)
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Aún puedes editar tu constancia :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    actualizarTabla() {
      this.$refs.refTablaConstancias.refresh()
    },
    deleteItem(item) {
      // Implemente swal
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, bórralo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('constancias/deleteConstancia', item)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: 'Tu constancia ha sido eliminado.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.actualizarTabla()
            }).catch(error => {
              this.$swal({
                title: 'Error',
                text: `${error.response.data.error}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Tu cita está seguro :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async myProvider(ctx) {
      const promise = store.dispatch('constancias/fetchConstancias', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        // const items = data
        const items = data.map(item => {
          const { ...rest } = item
          const nuevoItem = { ...rest }
          nuevoItem.persona = item.usuario_persona
          return nuevoItem
        })

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
  },
  setup() {
    const TRIAJES_APP_STORE_MODULE_NAME = 'constancias'

    // Register module
    if (!store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.registerModule(TRIAJES_APP_STORE_MODULE_NAME, useStoreConstancia)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.unregisterModule(TRIAJES_APP_STORE_MODULE_NAME)
    })
    return {
      avatarText,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
